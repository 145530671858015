<template>
    <div>
        <b-card-header>
            <b-card-title>Liste des sites WordPress à extraire</b-card-title>
        </b-card-header>
        <div class="accordion">
            <div
                v-for="blogToExtract in blog.blogsToExtract"
                :key="blogToExtract.id"
            >
                <div
                    v-b-toggle="blogToExtract.id"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="d-flex justify-content-between wordpress-list site px-1 py-1"
                    :class="selectedBlogToExtract.id === blogToExtract.id ? 'active' : ''"
                    @click="selectBlogToExtract(blogToExtract)"
                >
                    <div>
                        <h6
                            class="mb-0"
                        >
                            {{ blogToExtract.domain }}
                        </h6>
                    </div>
                    <div class="d-flex align-items-center">
                        <feather-icon icon="ArrowRightIcon" />
                    </div>
                </div>
                <b-collapse
                    :id="blogToExtract.id"
                    class="mx-1 mt-50"
                    accordion="wordpress-sites"
                >
                    <b-form-group
                        label="Domaine du blog"
                    >
                        <b-input-group
                            prepend="https://"
                        >
                            <b-form-input
                                v-model="blogToExtract.domain"
                                placeholder="Domaine du blog"
                                @keyup.enter="saveBlogToExtract"
                            />
                        </b-input-group>
                    </b-form-group>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="flat-danger"
                        @click="deleteBlogToExtract(blogToExtract)"
                    >
                        Supprimer ce blog
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="flat-primary"
                        class="ml-25"
                        @click="saveBlogToExtract"
                    >
                        Enregistrer
                    </b-button>
                </b-collapse>
            </div>
            <div v-if="blog.blogsToExtract.length === 0">
                <div class="d-flex px-1 py-1">
                    <p class="mb-0">
                        Aucun site WordPress à extraire
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
// import { v4 as uuidv4 } from 'uuid'
import {
    BButton,
    // BCardBody,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BCollapse,
    VBToggle,
} from 'bootstrap-vue'
import { database as db } from '@/firebase.config'

export default {
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    components: {
        BButton,
        // BCardBody,
        BCardHeader,
        BCardTitle,
        BFormInput,
        BFormGroup,
        BInputGroup,
        BCollapse,
    },
    props: {
        blog: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            selectedBlogToExtract: {},
        }
    },
    methods: {
        selectBlogToExtract(bte) {
            this.$emit('selectedBte', { ...bte })
            // document.querySelectorAll('.wordpress-list.site[aria-expanded="true"]').forEach(s => { s.click() })
            this.selectedBlogToExtract = { ...bte }
        },
        //
        // Cette fonction enregistre tous les blogToExtract
        saveBlogToExtract() {
            const blogRef = db.collection('blogs').doc(this.blog.id)
            blogRef.update({
                blogsToExtract: this.blog.blogsToExtract,
            })
            .then(() => {
                this.notify('Domaine modifié avec succès')
            })
        },
        deleteBlogToExtract(blog) {
            this.$swal({
                title: `Êtes vous sûr de vouloir supprimer le site ${blog.domain} ?`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            })
            .then(result => {
                if (result.value) {
                    this.blog.blogsToExtract = this.blog.blogsToExtract.filter(b => b.id !== blog.id)

                    const blogRef = db.collection('blogs').doc(this.blog.id)

                    blogRef.update({
                        blogsToExtract: this.blog.blogsToExtract,
                    })
                    .then(() => {
                        this.notify('Site WordPress supprimé avec succès', 'danger')
                        this.selectedBlogToExtract = {}
                        this.$emit('selectedBte', {})
                    })
                }
            })
        },
    },
}
</script>
