<template>
    <div>
        <b-card-header>
            <b-card-title>
                Ajouter un site WordPress
            </b-card-title>
        </b-card-header>
        <b-card-body>
            <b-form-group
                label="Domaine du blog"
            >
                <b-input-group
                    prepend="https://"
                >
                    <b-form-input
                        v-model="blogToExtract.domain"
                        placeholder="Domaine du blog"
                        @keyup.enter="addBlogToExtract"
                    />
                </b-input-group>
            </b-form-group>
            <div class="d-flex justify-content-center justify-content-md-end">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addBlogToExtract"
                >
                    Ajouter le site
                </b-button>
            </div>
        </b-card-body>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'
import {
    BButton,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BInputGroup,
} from 'bootstrap-vue'
import { database as db, firestore } from '@/firebase.config'

export default {
    directives: {
        Ripple,
    },
    components: {
        BButton,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BFormInput,
        BFormGroup,
        BInputGroup,
    },
    props: {
        blog: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            blogToExtract: {
                id: null,
                categories: [],
                domain: '',
            },
        }
    },
    methods: {
         //
        // Cette fonction ajoute un site wordpress dans la liste des sites wordpress ou il faudra
        // aller chercher des articles
        addBlogToExtract() {
            this.blogToExtract.id = uuidv4()

            this.blog.blogsToExtract.push({ ...this.blogToExtract })
            const blogRef = db.collection('blogs').doc(this.blog.id)
            blogRef.update({
                blogsToExtract: firestore.FieldValue.arrayUnion(this.blogToExtract),
            })

            this.blogToExtract.domain = ''
        },
    },
}
</script>
