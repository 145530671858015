<template>
    <b-overlay
        :show="is_loading"
    >
        <div>
            <b-row>
                <b-col>
                    <b-card
                        no-body
                    >
                        <b-row>
                            <b-col
                                md="6"
                                class="pr-0 border-right"
                            >
                                <edit-and-list-blog-to-extract
                                    :blog="blog"
                                    @selectedBte="selectBlogToExtract"
                                />
                                <hr>
                                <add-blog-to-extract
                                    :blog="blog"
                                />
                                <hr>
                                <time-settings-to-extract
                                    :blog="blog"
                                />
                            </b-col>
                            <b-col
                                md="6"
                                class="pl-0"
                            >
                                <list-categories
                                    :blog="blog"
                                    :bte="selectedBlogToExtract"
                                />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </b-overlay>
</template>

<script>

import {
    BCard,
    // BCardHeader,
    // BCardTitle,
    BRow,
    BCol,
    // BCardBody,
    // BCardText,
    // BLink,
    // BButton,
    // BFormGroup,
    // BFormInput,
    // BInputGroup,
    BOverlay,
    // BCollapse,
    VBToggle,
    // BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import { database as db } from '@/firebase.config'

import addBlogToExtract from './addBlogToExtract.vue'
import editAndListBlogToExtract from './editAndListBlogToExtract.vue'
import listCategories from './listCategories.vue'
import timeSettingsToExtract from './timeSettingsToExtract.vue'

export default {
    components: {
        BCard,
        // BCardHeader,
        // BCardTitle,
        BRow,
        BCol,
        // BCardBody,
        // BCardText,
        // // BLink,
        // BButton,
		// BFormGroup,
		// BFormInput,
		// BInputGroup,
        BOverlay,
        // vSelect,
        // BForm,
        // BCollapse,
        addBlogToExtract,
        editAndListBlogToExtract,
        listCategories,
        timeSettingsToExtract,
    },
    directives: {
        Ripple,
        'b-toggle': VBToggle,
	},
    data() {
        return {
            is_loading: true,
            is_categories_loading: false,
            blog: {
                blogsToExtract: [],
            },
            selectedBlogToExtract: {},
        }
    },
    watch: {
    },
    created() {
        console.log(this)
        this.blog.postInterval = null
        this.blog.stopAtTheNight = null
        this.blog.startAtTheMorning = 9
        this.blog.postsStatus = 'private'
        db.collection('blogs').doc(this.$route.params.id).get()
        .then(doc => {
            if (doc.exists) {
                const blog = Object.assign(this.blog, doc.data())
                blog.id = doc.id

                this.blog = blog
            } else {
                this.notify('Ce blog n\'existe pas', 'danger')
                this.$router.push({ name: 'blogs' })
            }
        })
        .catch(err => {
            console.log(err)
            this.notify('Ce blog n\'existe pas', 'danger')
            this.$router.push({ name: 'blogs' })
        })
        .finally(() => {
            this.is_loading = false
        })
    },
    methods: {
        selectBlogToExtract(bte) {
            this.selectedBlogToExtract = { ...bte }
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.wordpress-list {
    cursor: pointer;
    transition: background-color .2s;
}

.wordpress-list:hover {
    background-color: #f1f3f4;
}

.wordpress-list.active {
    background-color: #f1f3f4;
}
</style>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
