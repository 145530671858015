<template>
    <div>
        <b-card-header>
            <b-card-title>Catégorie du site</b-card-title>
        </b-card-header>
        <b-overlay
            :show="is_categories_loading"
        >
            <div
                v-if="bte.id"
            >
                <div
                    v-for="categorie in categories"
                    :key="categorie.id"
                    class="d-flex justify-content-between wordpress-list px-1 py-1"
                    :class="categorie.toExtract ? 'active' : ''"
                    @click="selectCategorie(categorie)"
                >
                    <div>
                        <h6
                            class="mb-0"
                        >
                            {{ categorie.name }}
                        </h6>
                    </div>
                    <div class="d-flex align-items-center">
                        <feather-icon
                            v-if="!categorie.toExtract"
                            icon="CircleIcon"
                        />
                        <feather-icon
                            v-else
                            icon="CheckCircleIcon"
                            class="text-primary"
                        />
                    </div>
                </div>
                <div
                    v-if="categories.length === 0"
                    class="p-1"
                >
                    Aucun catégorie trouvé pour ce site WordPress
                </div>
                <div
                    v-else
                    class="d-flex justify-content-center justify-content-md-end m-1"
                >
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="saveCategories"
                    >
                        Enregistrer
                    </b-button>
                </div>
            </div>
            <div v-else>
                <div class="p-1">
                    Veuillez sélectionner un site WordPress à extraire pour sélectionner une/des catégories
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
// import { v4 as uuidv4 } from 'uuid'
import {
    BButton,
    // BCardBody,
    BCardHeader,
    BCardTitle,
    BOverlay,
    // BFormInput,
    // BFormGroup,
    // BInputGroup,
    // BCollapse,
    // VBToggle,
} from 'bootstrap-vue'
import { database as db } from '@/firebase.config'

export default {
    directives: {
        Ripple,
    },
    components: {
        BButton,
        // BCardBody,
        BCardHeader,
        BCardTitle,
        BOverlay,
        // BFormInput,
        // BFormGroup,
        // BInputGroup,
        // BCollapse,
    },
    props: {
        blog: {
            required: true,
            type: Object,
        },
        bte: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            is_categories_loading: false,
            categories: [],
        }
    },
    watch: {
        bte(newBte, oldBte) {
            if (newBte.id !== oldBte.id && newBte.id) {
                this.is_categories_loading = true
                this.$http.get(`https://${this.bte.domain.replaceAll('/', '').replaceAll('\\', '\\')}/wp-json/wp/v2/categories?per_page=100`)
                .then(response => {
                    this.categories = []
                    response.data.forEach(c => {
                        this.categories.push({
                            id: c.id,
                            name: c.name,
                            toExtract: this.bte.categories.includes(c.id),
                        })
                    })
                })
                .catch(e => {
                    console.log(e)
                    this.notify('Erreur', 'danger', 'Le domaine du site WordPress n\'est pas valide ou est incompatible')
                    this.categories = []
                })
                .finally(() => {
                    this.is_categories_loading = false
                })
            }
        },
    },
    methods: {
        saveCategories() {
            console.log(this)
            const blogRef = db.collection('blogs').doc(this.blog.id)
            blogRef.update({
                blogsToExtract: this.blog.blogsToExtract,
            })
            .then(() => {
                this.notify('Catégories enregistrées avec succès !')
            })
        },
        selectCategorie(cat) {
            cat.toExtract = !cat.toExtract
            if (cat.toExtract) {
                this.bte.categories.push(cat.id)
            } else {
                const index = this.bte.categories.indexOf(cat.id)
                if (index > -1) {
                    this.bte.categories.splice(index, 1)
                }
            }
        },
    },
}
</script>
