<template>
    <div>
        <b-card-header>
            <b-card-title>
                Paramètres
            </b-card-title>
        </b-card-header>
        <b-card-body>
            <b-row>
                <b-col
                    md="6"
                >
                    <b-form-group
                        label="Interval de post"
                    >
                        <v-select
                            v-model="postInterval"
                            label="label"
                            :reduce="a => a.key"
                            :clearable="false"
                            :options="[
                                {
                                    key: null,
                                    label: 'Ne rien poster'
                                },
                                {
                                    key: 5,
                                    label: '5 minutes'
                                },
                                {
                                    key: 10,
                                    label: '10 minutes'
                                },
                                {
                                    key: 15,
                                    label: '15 minutes'
                                },
                                {
                                    key: 20,
                                    label: '20 minutes'
                                },
                                {
                                    key: 30,
                                    label: '30 minutes'
                                },
                                {
                                    key: 45,
                                    label: '45 minutes'
                                },
                                {
                                    key: 60,
                                    label: '1 heure'
                                },
                                {
                                    key: 90,
                                    label: '1 heure et demi'
                                },
                                {
                                    key: 120,
                                    label: '2 heures'
                                },
                                {
                                    key: 150,
                                    label: '2 heures et demi'
                                },
                                {
                                    key: 180,
                                    label: '3 heures'
                                },
                                {
                                    key: 240,
                                    label: '4 heures'
                                },
                                {
                                    key: 300,
                                    label: '5 heures'
                                },
                                {
                                    key: 360,
                                    label: '6 heures'
                                },
                                {
                                    key: 600,
                                    label: '10 heures'
                                },
                                {
                                    key: 720,
                                    label: '12 heures'
                                },
                                {
                                    key: 1440,
                                    label: '1 jour'
                                },
                                {
                                    key: 2880,
                                    label: '2 jours'
                                },
                            ]"
                        />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        label="Status des posts"
                    >
                        <v-select
                            v-model="postsStatus"
                            label="label"
                            :reduce="c => c.key"
                            :clearable="false"
                            :options="[
                                {
                                    key: 'private',
                                    label: 'Privés',
                                },
                                {
                                    key: 'publish',
                                    label: 'Publiques',
                                },
                            ]"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    v-if="postInterval !== null"
                    md="6"
                >
                    <b-form-group
                        label="Arrêter le soir"
                    >
                        <v-select
                            v-model="stopAtTheNight"
                            label="label"
                            :reduce="a => a.key"
                            :clearable="false"
                            :options="[
                                {
                                    key: null,
                                    label: 'Ne pas arrêter'
                                },
                                {
                                    key: 15,
                                    label: 'À 15 heures'
                                },
                                {
                                    key: 16,
                                    label: 'À 16 heures'
                                },
                                {
                                    key: 17,
                                    label: 'À 17 heures'
                                },
                                {
                                    key: 18,
                                    label: 'À 18 heures'
                                },
                                {
                                    key: 19,
                                    label: 'À 19 heures'
                                },
                                {
                                    key: 20,
                                    label: 'À 20 heures'
                                },
                                {
                                    key: 21,
                                    label: 'À 21 heures'
                                },
                                {
                                    key: 22,
                                    label: 'À 22 heures'
                                },
                                {
                                    key: 23,
                                    label: 'À 23 heures'
                                },
                                {
                                    key: 24,
                                    label: 'À minuit'
                                },
                            ]"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    v-if="stopAtTheNight !== null && postInterval !== null"
                    md="6"
                >
                    <b-form-group
                        label="Recommencer le matin"
                    >
                        <v-select
                            v-model="startAtTheMorning"
                            label="label"
                            :reduce="a => a.key"
                            :clearable="false"
                            :options="[
                                {
                                    key: 0,
                                    label: 'À minuit'
                                },
                                {
                                    key: 1,
                                    label: 'À 1 heure'
                                },
                                {
                                    key: 2,
                                    label: 'À 2 heures'
                                },
                                {
                                    key: 3,
                                    label: 'À 3 heures'
                                },
                                {
                                    key: 4,
                                    label: 'À 4 heures'
                                },
                                {
                                    key: 5,
                                    label: 'À 5 heures'
                                },
                                {
                                    key: 6,
                                    label: 'À 6 heures'
                                },
                                {
                                    key: 7,
                                    label: 'À 7 heures'
                                },
                                {
                                    key: 8,
                                    label: 'À 8 heures'
                                },
                                {
                                    key: 9,
                                    label: 'À 9 heures'
                                },
                                {
                                    key: 10,
                                    label: 'À 10 heures'
                                },
                                {
                                    key: 11,
                                    label: 'À 11 heures'
                                },
                                {
                                    key: 12,
                                    label: 'À 12 heures'
                                },
                            ]"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center justify-content-md-end">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="saveTimeSettings"
                >
                    Enregistrer
                </b-button>
            </div>
        </b-card-body>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
// import { v4 as uuidv4 } from 'uuid'
import {
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { database as db } from '@/firebase.config'

export default {
    directives: {
        Ripple,
    },
    components: {
        BButton,
        vSelect,
        BRow,
        BCol,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BFormGroup,
    },
    props: {
        blog: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            postInterval: null,
            startAtTheMorning: 9,
            stopAtTheNight: null,
            postsStatus: 'private',
        }
    },
    watch: {
        blog: {
            // eslint-disable-next-line
            handler: function () {
                this.postInterval = this.blog.postInterval
                this.stopAtTheNight = this.blog.stopAtTheNight
                this.startAtTheMorning = this.blog.startAtTheMorning
                this.postsStatus = this.blog.postsStatus
            },
            deep: true,
        },
    },
    created() {
        console.log(this)
        this.postInterval = this.blog.postInterval
        this.stopAtTheNight = this.blog.stopAtTheNight
        this.startAtTheMorning = this.blog.startAtTheMorning
        this.postsStatus = this.blog.postsStatus
        console.log(this.blog.postInterval, this.postInterval)
    },
    methods: {
        saveTimeSettings() {
            const blogRef = db.collection('blogs').doc(this.blog.id)

            blogRef.set({
                postInterval: this.postInterval,
                stopAtTheNight: this.stopAtTheNight,
                startAtTheMorning: this.startAtTheMorning,
                postsStatus: this.postsStatus,

            },
            {
                merge: true,
            })
            this.notify('Paramètres enregistrés avec succès !')
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
